@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@import "~react-perfect-scrollbar/dist/css/styles.css";

@import "./mixins/functions";
@import "./variables";
@import "./general";
@import "./typography";
@import "./inputs";
@import "./buttons";
@import "./card";
@import "./helper";
@import "./pages";

@media (min-width: 1400px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 100%;

    padding-left: 65px !important;
    padding-right: 65px !important;
  }
}

.brw-px-1 {
  border-width: 1px !important;
}
.fs-30 {
  font-size: 30px !important;
}
.pointer {
  cursor: pointer;
}
.br-15 {
  border-radius: 15px !important;
}

.bg-primary {
  background-color: #22a2ff;
}

.text-white {
  color: #fff;
}

.text-ghost {
  --bs-text-opacity: 1;
  --bs-ghost-rgb: 204, 204, 214;
  color: rgba(var(--bs-ghost-rgb), var(--bs-text-opacity)) !important;
}

.tt-capitalize {
  text-transform: capitalize;
}

.graph-filter-select {
  font-size: 1rem;
  padding: 0 0.5rem 0.25rem 0.5rem;
  border: 0;
  border-bottom: 1px solid #ccc;
}

.carousel-indicators {
  bottom: -30px !important;
  button {
    border-radius: 50%;
    width: 10px !important;
    height: 10px !important;
  }
}
.carousel-control-prev,
.carousel-control-next {
  width: 0px;
}
.carousel-inner {
  padding: 0 25px;
}

// Timeline Goals
.history-tl-container {
  ul.tl {
    margin: 20px 0;
    padding: 0;
    display: inline-block;
    li {
      list-style: none;
      margin: auto;
      margin-left: 50px;
      min-height: 50px;
      border-left: 1px solid #000000;
      padding: 0 0 50px 30px;
      position: relative;
      .item-detail {
        color: #000034;
        font-weight: 600;
        font-size: 12px;
      }
    }
    li:last-child {
      border-left: 0;
    }
    li::before {
      content: url("../images/calendar.svg");
      position: absolute;
      left: -16px;
      top: -5px;
      padding: 4px 4px;
      border: 1px solid #000000;
      border-radius: 500%;
      background: #fff;
      height: 30px;
      width: 30px;
      transition: all 500ms ease-in-out;
    }
    li:hover::before {
      border-color: #258cc7;
      transition: all 1000ms ease-in-out;
    }
  }
}
// Timeline Calendar
.timeline-calendar {
  padding-top: 20px;
  padding-right: 30px;
  padding-left: 30px;
  width: 370px;
  padding-bottom: 100px;
  position: relative;
  background: white;
  display: flex;
  border: 1px solid rgba(224, 224, 224, 1);

  button:nth-child(3) {
    border: none;
    background: none;
    position: absolute;
    right: 30px;
    top: 25px;
    width: 23px;
    height: 28px;
    overflow: hidden;
  }
  button:nth-child(2) {
    border: none;
    background: none;
    position: absolute;
    //width: 23px;
    left: 285px;
    top: 25px;
    height: 28px;
    overflow: hidden;
  }
  .react-datepicker__navigation {
    .react-datepicker__navigation-icon--previous {
      &::before {
        height: 12px;
        width: 12px;
        left: 0;
        border-color: #000;
        border-style: solid;
        border-width: 3px 3px 0 0;
        content: "";
        display: block;
        margin-top: 10px;
        transform: rotate(228deg);
      }
    }
    .react-datepicker__navigation-icon--next {
      &::before {
        height: 12px;
        width: 12px;
        border-color: #000;
        border-style: solid;
        border-width: 3px 3px 0 0;
        content: "";
        display: block;
        margin-top: 10px;
        transform: rotate(45deg);
      }
    }
  }
  .react-datepicker__month-container {
    width: 100%;
    // float:left;
    // padding: 20px;
    .react-datepicker__header {
      background-color: #fff;
      border: 0;
      .react-datepicker__current-month {
        text-align: left;
        margin-left: 0px;
        margin-top: 0px;
        margin-bottom: 35px;
        font-size: 22px;
        font-weight: normal;
      }
      .react-datepicker__day-names {
        display: flex;
        justify-content: center;
        font-size: 12px;
        .react-datepicker__day-name {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
        }
      }
    }
    .react-datepicker__month {
      color: #fff;
      margin: 0;
      // background: #22A2FF;
      .react-datepicker__week {
        display: flex;
        justify-content: center;
        .react-datepicker__day {
          width: 100%;
          border: 1px solid #fff;
          height: 45px;
          color: #fff;
          display: flex;
          margin: 0;
          align-items: center;
          justify-content: center;
          background: #ccccd6;
        }
        .react-datepicker__day--highlighted {
          background-color: #22a2ff;
        }
        .react-datepicker__day--outside-month {
          background-color: #ccccd6;
        }
        // .react-datepicker__day--keyboard-selected {
        //   background-color: #f04858;
        // }
        .react-datepicker__day--selected,
        .react-datepicker__day--in-range {
          background-color: #000034;
        }
      }
    }
  }
}

// Timeline
.timeline-tl-container {
  ul.tl {
    margin: 20px 0;
    padding: 0;
    display: inline-block;
    li {
      list-style: none;
      margin: auto;
      margin-left: 50px;
      min-height: 77px;
      border-left: 1px solid #000;
      padding: 0 0 50px 30px;
      position: relative;
      .item-detail {
        color: #000034;
        font-weight: 600;
        font-size: 12px;
      }
    }
    li::before {
      content: url("../images/calendar.svg");
      position: absolute;
      left: -16px;
      top: -5px;
      padding: 4px 4px;
      border: 1px solid #000;
      border-radius: 500%;
      background: #fff;
      height: 30px;
      width: 30px;
      transition: all 500ms ease-in-out;
    }
    li:hover::before {
      border-color: #258cc7;
      transition: all 1000ms ease-in-out;
    }
  }
}

.custom-radio {
  .form-check-input {
    height: 14px;
    width: 14px;
  }

  .form-check-label {
    position: relative !important;
    font-size: 30px;
    cursor: pointer;
  }
}

.custom-table {
  border-collapse: separate;
  border-spacing: 0 5px;

  thead > tr > th {
    color: black;
    font-weight: bold;
    font-size: 12px;
    padding: 5px 8px 0px;
    text-align: left;
  }
  tbody > tr > td {
    padding: 10px;
    font-size: 14px;
  }
  tbody > tr {
    background: #eeeeee59;
    border: 1px solid #eeeeee59;
  }
}
